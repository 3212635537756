<template>
  <div class="mt-4">
    <div class="row">
      <div class="col-md-6">
        <province-general
          :province="currentProvince"
          :nationalPlan="latestNationalPlan"
        ></province-general>
      </div>
      <div class="col-md-6 d-none d-md-block">
        <div class="border w-100">
          <map-svg :selectedProvinces="[currentProvince]" />
        </div>
      </div>
    </div>
    <province-summary-chart
      :province="currentProvince"
      :nationalPlan="latestNationalPlan"
      class="mt-3"
    >
    </province-summary-chart
    ><!-- graph -->
  </div>
</template>

<script>
import ProvinceSummaryChart from '@/components/ProvinceSummaryChart.vue';
import MapSvg from '@/components/MapSvg.vue';
import ProvinceGeneral from '@/components/ProvinceGeneral.vue';
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'province-energy-data',

  components: {
    MapSvg,
    ProvinceSummaryChart,
    ProvinceGeneral,
  },

  computed: {
    ...mapGetters('peno', ['currentProvince']),
    ...mapGetters(['latestNationalPlan']),
  },
  methods: {},
};
</script>

<style scoped></style>
